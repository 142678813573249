define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/posSettings/salesChannel',

  'modules/shop.cash-register-retail/models/settings/pointOfSale',
  'modules/shop.cash-register-retail/components/pointOfSale',
], (
  $, _, Backbone, Template,
  PointOfSaleSetting, PointOfSaleComponent,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'row',

  events: {
    'click [data-action="change-channel"]': 'changeClicked',
  },

  changeClicked() {
    PointOfSaleComponent.renderPointOfSalePopup();
  },

  serializeData() {
    return {
      posName: PointOfSaleSetting.get('name'),
    };
  },

}));
