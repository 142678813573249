define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/productListWithSearch/layout',

  './search/layout',
  './list/layout',
], (
  $, _, Backbone, Template,
  SearchView, ListView,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'product-list-with-search',

  initialize(options) {
    this.checkoutBtn = options.checkoutBtn;
    this.orderItemCollection = options.orderItemCollection;
    this.model = new Backbone.Model({
      mode: 'barcode',
      productCount: null,
    });
  },

  regions: {
    search: '[data-region=search]',
    list: '[data-region=list]',
  },

  onRender() {
    this.renderSearch();
    this.renderList();
  },

  renderSearch() {
    const region = this.getRegion('search');
    const view = new SearchView({
      model: this.model,
      checkoutBtn: this.checkoutBtn,
      orderItemCollection: this.orderItemCollection,
    });
    region.show(view);
  },

  renderList() {
    const region = this.getRegion('list');
    const view = new ListView({
      model: this.model,
      orderItemCollection: this.orderItemCollection,
    });
    region.show(view);
  },

}));
