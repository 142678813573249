define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/catalog/printMultiplePriceTags/row',
  'modules/common/components/locale',
], (
  $, _, Backbone, Template, Locale,
) => Backbone.Marionette.LayoutView.extend({
  tagName: 'tr',
  template: Template,

  modelEvents: {
    change: 'render',
  },

  initialize() {
    this.options.mainModel.on('change:status_filter', this.toggleState, this);
  },

  onDestroy() {
    this.options.mainModel.off('change:status_filter', this.toggleState, this);
  },

  onRender() {
    this.toggleState();
  },

  toggleState() {
    const filter = this.options.mainModel.get('status_filter');
    const showMe = !filter || this.model.get('status') === filter || filter === 'ANY';
    if (showMe) {
      this.$el.show();
    } else {
      this.$el.hide();
    }
  },

  getStatusLabelCls(status) {
    if (status === this.model.STATUS.MATCHED || status === this.model.STATUS.PRINTED || status === this.model.STATUS.PRINTING) {
      return 'success';
    }
    if (status === this.model.STATUS.SEARCHING || status === this.model.STATUS.IN_QUEUE) {
      return 'info';
    }
    return 'danger';
  },
  getStatusName(status) {
    if (status in this.model.STATUS_LOCALE) {
      return this.model.STATUS_LOCALE[status];
    }
    return status;
  },

  serializeData() {
    const data = this.model.toJSON();
    data.statusLabel = this.getStatusLabelCls(data.status);
    data.statusName = this.getStatusName(data.status);

    return data;
  },
}));
