define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/views/products/list/cellWithKeyboard',
  'modules/shop.cash-register-retail/templates/products/list/cellDiscount',
  'modules/shop.cash-register-retail/models/keyboard',
], (
  $, _, Backbone, CellView, Template, KeyboardModel,
) => CellView.extend({

  template: Template,

  className() {
    return `${this.model.get('id')} text-center`;
  },

  initialize(options) {
    options = options || {};

    this.productModel = options.productModel;
    this.keyboardMode = this.keyboardModel.MODE_PRODUCT_DISCOUNT;

    this.setPreviousPercentage();

    CellView.prototype.initialize.call(this, options);
  },

  onShow() {
    this.productModel.on('change:discount_ppu_wt', this.render, this);
    this.productModel.on('change:discount_percentage', this.render, this);
    this.productModel.on('discount:updated', this.setPreviousPercentage, this);
  },

  onDestroy() {
    this.productModel.off('change:discount_ppu_wt', this.render, this);
    this.productModel.off('change:discount_percentage', this.render, this);
    this.productModel.off('discount:updated', this.setPreviousPercentage, this);
  },

  setPreviousPercentage() {
    this.previousDiscountPrecentage = this.productModel.get('discount_percentage');
    this.previousDiscountPpuWt = this.productModel.get('discount_ppu_wt') || '0.00';
    this.previousPercentage = this.productModel.get('percentage');
  },

  setPercentageKey() {
    if (this.productModel.get('percentage')) {
      this.keyboardModel.setKey('%');
    } else {
      this.keyboardModel.resetKeys();
    }
  },
  onModeSelected() {
    this.previousPpu = this.productModel.get('ppu_wt');
    this.setPercentageKey();

    this.setPreviousPercentage();

    CellView.prototype.onModeSelected.call(this);
  },

  onModeDeselected() {
    this.keyboardModel.resetKeys();
  },

  onKeyPress(key) {
    if (this.keyboardModel.isNumberComaPercentKey(key)) {
      if (key === '%') {
        // mode change
        this.productModel.set('percentage', !this.productModel.get('percentage'));
        this.render();
        this.dotPos = -1; // on change we need to reset position
        this.firstInput = true;
        this.setPercentageKey();
      } else {
        let currentDiscount = null;
        if (this.firstInput) {
          currentDiscount = '0.00';
          this.firstInput = false;
        } else if (this.productModel.get('percentage')) {
          currentDiscount = this.productModel.get('discount_percentage');
        } else {
          currentDiscount = this.productModel.get('discount_ppu_wt');
        }

        let newDiscount = this.handleInputOnPrice(currentDiscount, key);
        if (this.productModel.get('percentage')) {
          if (parseFloat(newDiscount) > 100) {
            newDiscount = '100.00';
          }
          this.productModel.setDiscountPercentage(newDiscount);
        } else {
          const unitPrice = this.productModel.get('ppu_wt');
          if (parseFloat(newDiscount) > parseFloat(unitPrice)) {
            newDiscount = unitPrice;
          }
          this.productModel.setDiscountPpuWt(newDiscount);
        }
      }
    }
  },

  confirmPrice() {
    this.productModel.set({
      discount_ppu_wt:
                    this.productModel.getDiscountPpuWt(),
    });
    this.previousPpu = this.productModel.get('ppu_wt');
  },

  onConfirmation() {
    CellView.prototype.onConfirmation.call(this);
    this.setPreviousPercentage();
    this.productModel.set('percentage', true);

    this.confirmPrice();
    this.productModel.setPpuWt(
      this.productModel.get('ppu_wt'),
    );
    this.productModel.calculateTotalPrices();
  },

  onBackspacePress() {
    // Mark discount as changed
    this.firstInput = false;

    // Get the correct discount
    const currentDiscount = this.productModel.get('percentage')
      ? this.productModel.get('discount_percentage') : this.productModel.get('discount_ppu_wt');

    // Subtract discounts
    const newDiscount = this.removeDigitFromPrice(currentDiscount);

    // Apply the discount
    if (this.productModel.get('percentage')) {
      // Apply percentage base discount
      this.productModel.setDiscountPercentage(newDiscount);
    } else {
      // Apply price based discount
      this.productModel.setDiscountPpuWt(newDiscount);
    }
  },

  onCancel() {
    this.productModel.set({
      discount_percentage: this.previousDiscountPrecentage,
      discount_ppu_wt: this.previousDiscountPpuWt,
    });
    this.productModel.set('percentage', this.previousPercentage);
  },

  serializeData() {
    const data = this.productModel.toJSON();
    data.isPercentage = this.productModel.get('percentage');
    return data;
  },

}));
