define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/posSettings/receiptExample',

  'modules/admin/behaviors/loader',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/components/printing',
  'modules/shop.common/components/mode',

  'modules/profile/models/profile',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/shop.cash-register-retail/models/settings/AbstractWebPrinter',

  'modules/shop.cash-register-retail/views/popups/simplePopup',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
], (
  $, _, Backbone, Template,
  Loader, Locale,
  Printing, ModeComponent,
  ProfileModel, ReceiptPrinterModel, AbstractWebPrinter,
  SimplePopup, MessagePopup,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'row',

  behaviors: {
    Loader: {
      behaviorClass: Loader,
      name: 'receipt-example',
    },
  },

  initialize() {
    this.receiptJson = null;
  },

  ui: {
    print: '[data-ui=print]',
    printButton: '[data-ui=print-button]',
    noPrinterText: '[data-ui=no-printer-text]',
  },

  events: {
    'click @ui.print': 'printClicked',
  },

  onRender() {
    if (ReceiptPrinterModel.isWantedType()) {
      this.ui.noPrinterText.hide();
    } else {
      this.ui.printButton.hide();
    }
  },

  onShow() {
    ReceiptPrinterModel.on('change:id', this.render, this);
  },

  onDestroy() {
    ReceiptPrinterModel.off('change:id', this.render, this);
  },

  printClicked() {
    const def = this.loader.startLoader();

    def.fail((resp) => {
      if (resp && 'error' in resp) new MessagePopup().open(resp.error);
    });

    const receiptData = this.generateReceipt();
    ReceiptPrinterModel.printJsonData(receiptData).then(def.resolve, def.reject);
  },

  generateReceipt() {
    if (this.receiptJson) {
      return this.receiptJson;
    }

    const exampleOrder = new Backbone.Model({
      number: '000000123',
      value: 3,
      value_wt: 3,
      currency_iso3: 'EUR',
      'created_by.name': ProfileModel.getFullProfileName(),
      order_items: [
        {
          quantity: 1,
          name: Locale.translate('test_product'),
          ppu_wt: 1.00,
          price_wt: 1.00,
        },
        {
          quantity: 2,
          name: Locale.translate('product_with_subitems'),
          ppu_wt: 1.00,
          price_wt: 2.00,
          subitems: [
            {
              name: Locale.translate('subitem'),
              ppu_wt: 1.00,
              price_wt: 2.00,
            },
          ],
        },
      ],
    });

    this.receiptJson = Printing.createReceiptJson(exampleOrder);
    this.receiptJson = Printing.applyReceiptTemplateOptions(this.receiptJson);

    return this.receiptJson;
  },

}));
