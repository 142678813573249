define([
  'jquery',
  'underscore',
  'backbone',
  './item',
], (
  $, _, Backbone, ItemView,
) => Backbone.Marionette.CollectionView.extend({

  childView: ItemView,

  className: 'categories-container',

}));
