define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/posSettings/configureProductionGroups/item.hbs',

  'modules/admin/behaviors/loader',
  'modules/common/components/locale',
  'toastr',

  'modules/shop.cash-register-retail/components/productionGroupPrint',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',

  'modules/shop.cash-register-retail/views/posSettings/receiptPrinterSetting',
], (
  $, _, Backbone, Template,
  Loader, Locale, Toastr,
  ProductionGroupPrint, ReceiptPrinterSetting,
  ReceiptPrinterSettingView,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  events: {
    'click [data-example]': 'exampleClicked',
  },

  regions: {
    printer: '[data-region="printer"]',
  },

  initialize({ model, printers, productionGroupPrinters }) {
    this.model = model;
    this.printers = printers;
    this.productionGroupPrinters = productionGroupPrinters;
    this.initializePrinterModel();
  },

  initializePrinterModel() {
    this.printerModel = new Backbone.Model();

    const productionGroupId = this.model.id;
    const productionGroupPrinter = this.productionGroupPrinters[productionGroupId];

    if (productionGroupPrinter) {
      this.printerModel.set('id', productionGroupPrinter.id);
      this.printerModel.set('type', productionGroupPrinter.type);
    }

    this.printerModel.on('change:id', this.handlePrinterUpdate, this);
  },

  handlePrinterUpdate(printerModel) {
    this.triggerMethod('child:printer:update', this.model.id, printerModel);
  },

  onRender() {
    const region = this.getRegion('printer');

    const view = new ReceiptPrinterSettingView({
      model: this.printerModel,
      targetPathId: 'id',
      targetPathType: 'type',
      printers: this.printers,
      updateInModel: true,
      setFirstOptionInModel: true,
      printerModel: this.printerModel,
    });
    region.show(view);
  },

  exampleClicked() {
    const productionGroupId = this.model.id;
    const printer = this.productionGroupPrinters[productionGroupId];
    const id = printer ? printer.id : ReceiptPrinterSetting.get('id');
    const type = printer ? printer.type : ReceiptPrinterSetting.get('type');

    if (id && type) {
      const def = this.loader.startLoader();
      ProductionGroupPrint.printTest(id, type, productionGroupId)
        .then(def.resolve)
        .catch((err) => {
          def.reject();

          if (err && err.error) {
            Toastr.error(err.error, Locale.translate('failed_to_print_on_printer'));
          } else {
            Toastr.error(Locale.translate('failed_to_print_on_printer'));
          }
        });
    }
  },

  onDestroy() {
    this.printerModel.off('change:id', this.handlePrinterUpdate, this);
  },

}));
