define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/total/discount.hbs',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/common/components/currency',
  'modules/shop.cash-register-retail/behaviors/cellWithKeyboard',
  'modules/shop.cash-register-retail/models/keyboard',
], (
  $, _, Backbone, Template,
  Locale, OrderItemCollection, Currency, WithKeyboardBehaviour, Keyboard,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'designer-box rounded all-product-discount',

  ui: {
    discountAmount: '[data-ui="discount-amount"]',
    discountHeader: '[data-ui="discount-header"]',
    discountHeaderSet: '[data-ui="discount-set-header"]',
    popup1: '[data-region="popupDiscount"]',
  },

  behaviors: {
    WithKeyboardBehaviour: {
      behaviorClass: WithKeyboardBehaviour,
    },
  },

  initialize(options) {
    this.keyboardMode = Keyboard.MODE_ALL_DISCOUNT;
    this.dotPos = -1;
    this.orderItemCollection = options.orderItemCollection || OrderItemCollection;
  },

  onShow() {
    this.orderItemCollection.on('all', this.renderLater, this);
    this.ui.discountHeaderSet.hide();
  },

  onDestroy() {
    this.orderItemCollection.off('all', this.renderLater, this);
  },

  render(a, b, c) {
    if (!this.isFocused) {
      // prevent full rendering when focused
      Backbone.Marionette.LayoutView.prototype.render.call(this, a, b, c);
    } else {
      // only percentage
      this.renderPercentage();
    }
  },
  renderLater: _.debounce(function () {
    // When editing edit field but not clicking confirm, and clicking on pay in the checkout overview.
    // It causes an update. and causes this to render after its been destroyed.
    if (!this.isDestroyed) {
      this.render();
    }
  }, 5),

  renderPercentage() {
    const discount = `${this.percentage}%`;
    this.ui.discountAmount.text(discount);
  },

  onModeSelected() {
    this.keyboardModel.setKey('%');
    this.percentage = '0.00';
    this.renderPercentage();
    this.ui.discountHeaderSet.show();
    this.ui.discountHeader.hide();

    this.triggerMethod('discount:clicked');
  },

  onModeDeselected() {
    this.keyboardModel.resetKeys();
    this.ui.discountHeaderSet.hide();
    this.ui.discountHeader.show();
    this.keyboardModel.resetMode();
    // only render if the view is not destroyed
    if (!this.isDestroyed) {
      this.render();
    }
  },

  onKeyPress(key) {
    if (this.keyboardModel.isNumberComaKey(key)) {
      const currentDiscount = this.percentage;
      let newDiscount = this.handleInputOnPrice(currentDiscount, key);

      if (parseFloat(newDiscount) >= 100) {
        newDiscount = '100.00';
      }
      this.percentage = newDiscount;
    }
  },

  onConfirmation() {
    const { percentage } = this;
    this.orderItemCollection.applyPercentageDiscountToAllItemsWithConfirmation(percentage);
  },

  onBackspacePress() {
    // Update the discount percentage
    this.percentage = this.removeDigitFromPrice(this.percentage);
    this.renderPercentage();
  },

  onCancel() {
  },

  serializeData() {
    const discount = this.orderItemCollection.getTotalDiscountWt();
    return {
      discount,
      currency_iso3: 'EUR',
    };
  },

}));
