define([
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/description/serials.hbs',
  'modules/shop.cash-register-retail/views/products/description/serialsInput.js',
], (
  _, Backbone, Template, SerialsInputView,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  regions: {
    serialInput: '[data-region="serials-input"]',
  },

  onRender() {
    const region = this.getRegion('serialInput');
    const view = new SerialsInputView({
      model: this.model,
    });
    region.show(view);
    this.serialsView = view;
  },

  saveClicked() {
    this.model.setSerialNos(this.serialsView.getValue());
  },

}));
