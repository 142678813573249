define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/views/products/list/cell',
  'modules/shop.cash-register-retail/templates/products/list/cellStock',
  'modules/shop.cash-register-retail/components/productStock.js',
], (
  $, _, Backbone, CellView, Template, ProductStock,
) => CellView.extend({

  template: Template,

  initialize(options) {
    this.productModel = options.productModel;
  },

  onShow() {
    this.productModel.on('change:quantity', this.render, this);
    this.productModel.on('change:stock-check', this.render, this);
  },

  onDestroy() {
    this.productModel.off('change:quantity', this.render, this);
    this.productModel.off('change:stock-check', this.render, this);
  },

  getStockClass() {
    const quantity = parseInt(this.productModel.get('quantity')) || 0;
    const stockQuantity = this.productModel.get('stock_quantity') || 0;
    const stockUnlimited = this.productModel.get('stock_unlimited') || false;
    const stockBackorder = this.productModel.get('stock_backorder') || false;

    return ProductStock.getClass(
      stockQuantity - quantity, stockUnlimited, stockBackorder,
    );
  },

  getStock() {
    return ProductStock.getStock(
      this.productModel.get('stock_quantity') || 0,
      this.productModel.get('stock_unlimited'),
    );
  },

  serializeData() {
    return {
      stockAmount: this.getStock(),
      stockClass: this.getStockClass(),
      stockLoading: !!this.productModel.get('stock-check'),
    };
  },

}));
