define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/blocks/products/item.hbs',

  'modules/upx/components/upx',
], (
  $, _, Backbone, Template,
  Upx,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'designer-box rounded',

  events: {
    click: 'handleClicked',
  },

  handleClicked: _.throttle(function () {
    this.triggerMethod('child:clicked', this.model);
  }, 500),

  serializeData() {
    const data = this.model.toJSON();

    data.image = 'images/no-product-image.png';
    if (this.model.has('flat_product.main_image.url')) {
      data.image = Upx.getResourceUrl(this.model.get('flat_product.main_image.url'));
    }

    data.discounted = this.model.get('product_default_price.ppu_wt') !== this.model.get('product_price.ppu_wt');

    return data;
  },

}));
