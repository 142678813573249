define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/catalog/printMultiplePriceTags/printing',

  'modules/common/components/locale',
  'modules/admin/behaviors/loader',
  'modules/common/components/uuid',
  'modules/upx/components/upx',

  './row',
  'modules/shop.cash-register-retail/components/productSearch',
  'modules/shop.cash-register-retail/components/productCache',
  'modules/shop.cash-register-retail/components/printing',

  'modules/shop.cash-register-retail/collections/upx/FeaturedAttribute',
], (
  $, _, Backbone, Template,
  Locale, Loader, Uuid, Upx,
  ChildView, ProductSearchComponent, ProductCacheComponent, PrintingComponent,
  FeaturedAttributeCollection,
) => {
  const STATUS = {
    IN_QUEUE: 'IN_QUEUE',
    SEARCHING: 'SEARCHING',
    MATCHED: 'MATCHED',
    PRODUCT_NOT_FOUND: 'PRODUCT_NOT_FOUND',
    PRINTING: 'PRINTING',
    ERROR: 'ERROR',
    PRINTED: 'PRINTED',
  };

  const STATUS_LOCALE = {
    IN_QUEUE: Locale.translate('in_queue'),
    SEARCHING: Locale.translate('matching'),
    MATCHED: Locale.translate('matched'),
    PRODUCT_NOT_FOUND: Locale.translate('product_not_found'),
    PRINTING: Locale.translate('send_to_to_printer'),
    PRINTED: Locale.translate('printed'),
    ERROR: Locale.translate('error'),
  };

  return Backbone.Marionette.CompositeView.extend({
    template: Template,

    STATUS,
    STATUS_LOCALE,

    collectionModel: Backbone.Model.extend({
      STATUS,
      STATUS_LOCALE,

      defaults: {
        status: STATUS.IN_QUEUE,
        product_id: null,
        product_name: null,
      },
    }),

    childViewContainer: '@ui.rows',
    childView: ChildView,
    childViewOptions(model, index) {
      // do some calculations based on the model
      return {
        model,
        mainModel: this.model,
      };
    },

    ui: {
      rows: 'tbody',
      statusSelect: '[data-ui="status-select"]',
      progressBar: '[role="progressbar"]',
      progressBarSuccess: '.progress > .progress-bar-success',
      progressBarError: '.progress > .progress-bar-danger',
    },

    events: {
      'change @ui.statusSelect': 'onStatusChange',
    },

    behaviors: {
      Loader: {
        behaviorClass: Loader,
      },
    },

    /**
         * pass barcodes via the options as array
         */
    initialize() {
      this.barcodes = this.options.barcodes;
      this.type = this.options.type;

      this.model = new Backbone.Model({
        processing: true,
      });
      this.collection = new (Backbone.Collection.extend({
        model: this.collectionModel,
      }))();

      const self = this;
      _.each(this.barcodes, (barcode) => {
        barcode = barcode.trim();
        if (barcode !== '') {
          self.collection.add({
            id: Uuid.genRandom(),
            barcode,
          });
        }
      });
    },

    onShow() {
      const self = this;
      this.matchProducts().then(() => {
        self.onProcess();
      });

      this.ui.statusSelect.append(
        `<option value="ANY">${Locale.translate('any_status')}</option>`,
      );
      _.each(STATUS, (status) => {
        self.ui.statusSelect.append(
          `<option value="${status}">${STATUS_LOCALE[status]}</option>`,
        );
      });

      this.model.on('all', this.renderProgressBar, this);
      this.renderProgressBar();
    },

    renderProgressBar: _.debounce(function () {
      // Because of the debounce we need to check if the view is not destroyed, because we interact with the UI.
      if (!this.isDestroyed) {
        this.ui.progressBarError.css('width', `${this.model.get('error_per')}%`);
        this.ui.progressBarError.find('span').text(this.model.get('error'));
        this.ui.progressBarSuccess.css('width', `${this.model.get('progress_per')}%`);
        this.ui.progressBarSuccess.find('span').text(this.model.get('done'));

        if (!this.model.get('processing')) {
          this.ui.progressBar.removeClass('progress-bar-striped');
        }
      }
    }, 100),

    onStatusChange() {
      const current = this.ui.statusSelect.val();
      if (current === 'ANY') {
        this.model.set('status_filter', null);
      } else {
        this.model.set('status_filter', current);
      }
    },

    onProcess: _.debounce(function () {
      const errorNo = this.collection.where({ status: STATUS.PRODUCT_NOT_FOUND }).length;
      const totalNo = this.collection.length;

      this.model.set('total', totalNo);
      this.model.set('error', errorNo);
      this.model.set('done', 0);
      this.model.set('progress_per', 0);
      this.model.set('error_per', 100 * (errorNo / totalNo));

      this.onProcessNext(totalNo);
    }, 100),

    onDestroy() {
      if (this.model) {
        this.model.set('processing', false);
        this.model.off('all', this.renderProgressBar, this);
      }
    },

    onProcessNext(totalNo) {
      const model = this.collection.findWhere({
        status: STATUS.MATCHED,
      });
      if (model) {
        if (this.model.get('processing')) {
          model.set('status', STATUS.PRINTING);
          const self = this;

          let printDef = $.Deferred();
          if (this.type === 'pricetag') {
            printDef = PrintingComponent.printPriceTagFromData(
              model.get('ppu_wt'),
              model.get('default_ppu_wt'),
              model.get('has_discount'),
              model.get('product_name'),
              model.get('sku'),
              model.get('barcode'),
            );
          } else if (this.type === 'sticker') {
            printDef = PrintingComponent.printPriceStickerFromData(
              model.get('ppu_wt'),
              model.get('default_ppu_wt'),
              model.get('has_discount'),
              model.get('iso3'),
              model.get('product_name'),
              model.get('sku'),
              model.get('barcode'),
            );
          }

          printDef.then(() => {
            model.set('status', STATUS.PRINTED);

            const doneNo = self.model.get('done') + 1;
            self.model.set('done', doneNo);
            self.model.set('progress_per', 100 * (doneNo / totalNo));
            if (doneNo === totalNo) {
              self.model.set('processing', false);
            } else {
              self.onProcessNext(totalNo);
            }
          }, () => {
            model.set('status', STATUS.ERROR);

            const errorNo = self.model.get('error') + 1;
            self.model.set('error', errorNo);
            self.model.set('error_per', 100 * (errorNo / totalNo));

            self.onProcessNext(totalNo);
          });
        }
      } else {
        this.model.set('processing', false);
      }
    },

    matchProducts(finalDef) {
      finalDef = finalDef || new $.Deferred();

      if (this.model.get('processing')) {
        const self = this;
        const needMatching = this.collection.where({
          status: STATUS.IN_QUEUE,
        });
        if (needMatching.length) {
          const model = needMatching[0];
          const barcode = model.get('barcode');
          model.set('status', STATUS.SEARCHING);

          const def = ProductSearchComponent.searchProduct(barcode);
          def.then((response) => {
            if (response.model) {
              model.set('status', STATUS.MATCHED);
              const product = response.model;

              let printableShortnameText = false;
              const contentVars = product.get('flat_product.content_vars');
              if (contentVars) {
                contentVars.forEach((contentVar) => {
                  const attr_id = contentVar.attribute_id;
                  if (attr_id === FeaturedAttributeCollection.getAttributeIdByAlias(FeaturedAttributeCollection.ALIAS_PRINTABLE_SHORTNAME)) {
                    if ('value_label' in contentVar) {
                      printableShortnameText = contentVar.value_label;
                    } else {
                      printableShortnameText = contentVar.value;
                    }
                  }
                });
              }

              model.set('shop_product_id', product.get('id'));
              model.set('product_name', printableShortnameText || product.get('flat_product.title'));
              model.set('sku', product.get('flat_product.product.sku'));
              model.set('ppu_wt', product.get('product_price.ppu_wt'));
              model.set('default_ppu_wt', product.get('product_default_price.ppu_wt'));
              model.set('has_discount', !(product.get('product_price_id') === product.get('product_default_price_id')));
              model.set('iso3', product.get('product_price.currency_iso3'));
              model.set('barcode', ProductCacheComponent.getBarcodeFromShopFlatProduct(product));
            } else {
              model.set('status', STATUS.PRODUCT_NOT_FOUND);
            }
          }, () => {
            // not found
            model.set('status', STATUS.PRODUCT_NOT_FOUND);
          });

          def.always(() => {
            self.matchProducts(finalDef);
          });
        } else {
          finalDef.resolve();
        }
      } else {
        finalDef.reject();
      }
      return finalDef.promise();
    },

    serializeData() {
      return this.model.toJSON();
    },
  });
});
