define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/list/rowLoader',

  'modules/admin/behaviors/loader',
], (
  $, _, Backbone, Template,
  Loader,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'tr',

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  onShow() {
    this.loader.startLoader();
  },

  serializeData() {
    return {
      colspan: this.options.columns.length,
    };
  },
}));
