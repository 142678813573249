define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/list/layout',
  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.cash-register-retail/components/feature',

  './cellQuantity',
  './cellDescription',
  './cellStock',
  './cellDiscount',
  './cellPpu',
  './cellPrice',
  './cellDelete',

  './cellReturnQuantity',
  './cellAddonTotal',
  './cellDeliverQuantity',

  'modules/admin/behaviors/loader',
], (
  $, _, Backbone, Template, OrderItemCollection, Feature,
  QuantityView, DescriptionView, StockView, DiscountView, PpuView, PriceView, DeleteView,
  ReturnQuantityView, AddonTotalView, CellDeliverQuantityView,
  Loader,
) => Backbone.Marionette.CollectionView.extend({

  template: Template,

  tagName: 'tr',

  childEvents: {
    'product:clicked': 'productClicked',
    'product:total': 'productTotal',
    'product:quantity': 'productQuantity',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  modelEvents: {
    'change:quantity': 'quantityChanged',
    'show:loader': 'showLoader',
  },

  showLoader(def) {
    const loaderDef = this.loader.startLoader();
    def.always(loaderDef.resolve);
  },

  productClicked(view, productModel) {
    this.triggerMethod('product:clicked', productModel);
  },

  productQuantity(view, data) {
    this.triggerMethod('product:quantity', data);
  },

  productTotal(view, data) {
    this.triggerMethod('product:total', data);
  },

  getChildView(model) {
    switch (model.get('id')) {
      case 'quantity':
        return QuantityView;
      case 'description':
        return DescriptionView;
      case 'stock':
        return StockView;
      case 'discount':
        return DiscountView;
      case 'ppu':
        return PpuView;
      case 'price':
        return PriceView;
      case 'return_quantity':
        return ReturnQuantityView;
      case 'addonTotal':
        return AddonTotalView;
      case 'deliverQuantity':
        return CellDeliverQuantityView;
      default:
        return DeleteView;
    }
  },

  childViewOptions(model) {
    const id = model.get('id');
    const productType = this.options.model.get('type');
    const isCardProduct = OrderItemCollection.isCardType(productType);
    const editableColumn = _.contains(this.options.editable_columns, id);
    const positiveQuantity = this.model.get('quantity') >= 0;
    const allowedEditableNegativeQuantity = ['ppu'];

    let isEditable = editableColumn
      && (
        positiveQuantity
        || allowedEditableNegativeQuantity.indexOf(id) !== -1
      );

    if (id === 'quantity') {
      if (isCardProduct) {
        isEditable = false;
      } else if (productType === OrderItemCollection.TYPE_NEGATIVE_PRODUCT) {
        isEditable = true;
      }
    }
    if (id === 'description'
        && productType === OrderItemCollection.TYPE_PRODUCT
        && !positiveQuantity
        && this.hasSerialNumbers()
    ) {
      // special case where we need an edit for the serial numbers
      isEditable = true;
    }

    if (id === 'return_quantity') {
      const refunded_quantity = this.model.get('refunded_quantity');
      if (refunded_quantity && refunded_quantity >= this.model.get('quantity')) {
        isEditable = false;
      }
    }

    if (
      editableColumn
      && (id === 'description' || id === 'quantity')
      && productType === OrderItemCollection.TYPE_USED_GOODS
    ) {
      // special case it's used goods
      isEditable = true;
    }

    return {
      isEditable,
      productModel: this.model,
      onKeyPadOpen: this.options.onKeyPadOpen,
      itemPriceWithName: this.options.itemPriceWithName,
      showUnitPriceBelowDescription: this.options.showUnitPriceBelowDescription,
    };
  },

  hasSerialNumbers() {
    return !!this.model.get('has_serial_numbers') && Feature.isProductSerialFeatureEnabled();
  },

  initialize(options) {
    options = options || {};
    this.collection = new Backbone.Collection();
    const self = this;
    options.columns.forEach((column) => {
      self.collection.add({ id: column });
    });
    this.currentQuantity = 0;
    if (options.loadQuantityOnInitialize) {
      this.currentQuantity = this.model.get('quantity') || 0;
    }
  },

  onShow() {
    this.quantityChanged();

    this.model.on('product:needs-weight', () => {
      this.triggerMethod('product:needs-weight', this.model);
    });

    this.model.on('product:needs-description', () => {
      this.triggerMethod('product:needs-description', this.model);
    });
  },

  quantityChanged(forceRefresh) {
    const newQuantity = this.model.get('quantity');
    if (newQuantity > this.currentQuantity || forceRefresh) {
      this.el.scrollIntoView();
      this.$el.addClass('pulse-success');
      setTimeout(() => {
        this.$el.removeClass('pulse-success');
      }, 2000);
      this.currentQuantity = newQuantity;
    }
  },

}));
