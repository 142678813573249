define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/posSettings/webReceiptButton',

  'modules/shop.cash-register-retail/models/settings/webPrinterDevice',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/toaster',
], (
  $, _, Backbone, Template,
  WebPrinterDeviceSetting, ReceiptPrinterSetting,
  Locale, Toaster,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'button',

  className: 'form-control',

  initialize(options) {
    this.triggerUpdateInModel = options.triggerUpdateInModel || false;
    this.targetPath = options.targetPath;
  },

  events: {
    click: 'btnClicked',
  },

  async btnClicked() {
    let printerId = null;

    // claiming & setting device
    try {
      const device = await ReceiptPrinterSetting.selectDevice();
      printerId = await ReceiptPrinterSetting.claimDevice(device);
    } catch (e) {
      if (e.code === 19) {
        Toaster.error(Locale.translate('cannot_select_printer_since_it_s_used_by_the_operating_system_dot'));
      }
      // enters here when you cancel the selecting of the usb navigation
      WebPrinterDeviceSetting.unsetDevice();
    }

    if (printerId) {
      ReceiptPrinterSetting.setPrinter(printerId, ReceiptPrinterSetting.PRINTER_TYPE_WEB);
    } else {
      ReceiptPrinterSetting.resetPrinter();
    }

    this.render();
  },

  serializeData() {
    const hasPrinter = WebPrinterDeviceSetting.hasDevice();
    return {
      hasPrinter,
      printerName: hasPrinter ? WebPrinterDeviceSetting.getDevice().productName : '',
    };
  },

  onShow() {
    this.model.on(`change:${this.targetPath}`, this.render, this);
  },

  onDestroy() {
    this.model.off(`change:${this.targetPath}`, this.render, this);
  },

}));
