define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/behaviors/cellWithKeyboard',
  'modules/shop.cash-register-retail/models/keyboard',
], (
  $, _, Backbone, Locale,
  WithKeyboardBehaviour, KeyboardModel,
) => Backbone.Marionette.LayoutView.extend({

  template() {}, // No template needed.

  tagName: 'textarea',

  className: 'form-control',

  attributes() {
    return {
      placeholder: Locale.translate('description'),
      rows: '2',
    };
  },

  behaviors: {
    WithKeyboardBehaviour: {
      behaviorClass: WithKeyboardBehaviour,
      resetOnConfirmation: false,
      resetOnCancel: false,
    },
  },

  events: {
    keyup: 'inputChanged',
  },

  inputChanged() {
    this.model.setOrderDescription(this.$el.val());
  },

  initialize() {
    this.keyboardMode = KeyboardModel.MODE_PRODUCT_ORDER_DESCRIPTION;
  },

  onShow() {
    this.setValue();
  },

  setValue() {
    this.$el.val(this.model.get('order_description'));
  },

  saveClicked() {
    const orderDescription = this.model.get('order_description');
    this.model.setDescription(orderDescription);
  },

  onKeyPress(key) {
  },
  onBackspacePress() {
  },

  onModeDeselected() {
  },

  onConfirmation() {
  },

  onModeSelected() {
  },

  onCancel() {
  },

}));
