define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/productListWithSearch/search/layout',

  'modules/shop.cash-register-retail/views/inputs/productSearchInput',
  'modules/shop.cash-register-retail/views/inputs/stringInput',
  'modules/shop.cash-register-retail/collections/currentOrderItem',

  'modules/common/components/locale',
  'modules/shop.common/components/onScreenKeyboard',
], (
  $, _, Backbone, Template,
  ProductSearchInput, StringInput, OrderItemCollection,
  Locale, OnScreenKeyboard,
) => Backbone.Marionette.LayoutView.extend({

  MODE_BARCODE: 'barcode',
  MODE_PRODUCT: 'product',

  template: Template,

  className: 'product-search-container',

  modelEvents: {
    'change:mode': 'renderInput toggleButton',
    'change:productCount': 'updateProductCount',
  },

  ui: {
    barcode: '[data-ui=barcode]',
    product: '[data-ui=product]',
    reset: '[data-ui=reset]',
  },

  events: {
    'click @ui.barcode': 'barcodeSelected',
    'touchend @ui.barcode': 'barcodeSelected',

    'click @ui.product': 'productSelected',
    'touchend @ui.product': 'productSelected',

    'click @ui.reset': 'barcodeSelected',
    'touchend @ui.reset': 'barcodeSelected',
  },

  barcodeSelected: _.debounce(function () {
    this.model.set({
      mode: this.MODE_BARCODE,
      productCount: null,
    });
  }, 50),

  productSelected: _.debounce(function () {
    this.model.set({
      mode: this.MODE_PRODUCT,
      productCount: null,
    });
  }, 50),

  regions: {
    input: '[data-region=input]',
  },

  initialize(options) {
    this.checkoutBtn = options.checkoutBtn;
    this.orderItemCollection = options.orderItemCollection || OrderItemCollection;
  },

  onRender() {
    this.renderInput();
    this.toggleButton();
  },

  renderInput() {
    if (this.model.get('mode') === this.MODE_BARCODE) {
      this.renderBarcodeInput();
    } else if (this.model.get('mode') === this.MODE_PRODUCT) {
      this.renderProductInput();
    }
  },

  renderBarcodeInput() {
    const region = this.getRegion('input');
    const view = new ProductSearchInput({
      placeholder: Locale.translate('scan_barcode_to_add_a_product'),
      checkoutBtn: this.checkoutBtn,
      orderItemCollection: this.orderItemCollection,
    });
    region.show(view);
  },

  renderProductInput() {
    const region = this.getRegion('input');
    const view = new StringInput({
      placeholder: Locale.translate('search_in_all_products'),
      autoFocus: true,
    });
    region.show(view);

    view.on('value:search', (query) => {
      this.model.trigger('start:search', query);
      // Hide the keyboard to give back some screen real estate.
      // The user can click the input again to get the keyboard back.
      OnScreenKeyboard.unregisterTargetInput();
    });
  },

  toggleButton() {
    if (this.model.get('mode') === this.MODE_BARCODE) {
      this.ui.barcode.addClass('selected');
      this.ui.product.removeClass('selected');
      this.ui.reset.hide();
    } else if (this.model.get('mode') === this.MODE_PRODUCT) {
      this.ui.barcode.removeClass('selected');
      this.ui.product.addClass('selected');
      this.ui.reset.show();
    }
  },

  updateProductCount() {
    const productCount = this.model.get('productCount');
    let text = Locale.translate('product');
    if (typeof productCount === 'number') {
      if (productCount > 99) {
        text += ' (99+)';
      } else {
        text += ` (${productCount})`;
      }
    }

    this.ui.product.text(text);
  },

}));
