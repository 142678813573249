define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/blocks/products/itemFull.hbs',

  'modules/upx/components/upx',
], (
  $, _, Backbone, Template,
  Upx,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  events: {
    click: 'handleClicked',
  },

  handleClicked: _.throttle(function () {
    this.triggerMethod('child:clicked', this.model);
  }, 500),

  serializeData() {
    const data = this.model.toJSON();
    data.has_image = this.model.has('flat_product.main_image.url');
    data.discounted = this.model.get('product_default_price.ppu_wt') !== this.model.get('product_price.ppu_wt');
    return data;
  },

  fixImage() {
    let image = 'images/no-product-image.png';
    if (this.model.has('flat_product.main_image.url')) image = Upx.getResourceUrl(this.model.get('flat_product.main_image.url'));

    this.$el.css('backgroundImage', `url('${image}')`);
  },

  ensureSquareness() {
    // Timeout needed to ensure everything is rendered
    setTimeout(() => {
      const width = this.$el.width();
      const height = this.$el.height();
      if (width > height) {
        this.$el.css('height', `${width}px`);
      } else {
        this.$el.css('width', `${height}px`);
      }
    }, 0);
  },

  onRender() {
    this.fixImage();
    this.ensureSquareness();
  },

}));
