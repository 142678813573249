define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/posSettings/dropdownSetting',

  'modules/common/components/locale',
], (
  $, _, Backbone, Template,
  Locale,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  defaults: {
    targetPath: null,
    notSelectedLabel: null,
    autoSelect: true,
    label: null,
    items: [], // consist out of items with ids and labels
  },

  initialize(options) {
    this.options = _.extend({}, this.defaults, options);
  },

  getItems() {
    return this.options.items;
  },

  serializeData() {
    const opt = this.options;
    const items = this.getItems();
    return {
      targetPath: opt.targetPath,
      notSelectedLabel: opt.notSelectedLabel,
      label: opt.label,
      labelLowercase: _.isString(opt.label) ? opt.label.toLowerCase() : opt.label,
      items,
      hasItems: _.isArray(items) ? items.length > 0 : false,
    };
  },

  ui: {
    select: '[data-ui=select]',
  },

  events: {
    'change @ui.select': 'selectChanged',
  },

  selectChanged() {
    const value = this.ui.select.val();

    // Set the model
    this.model.set({
      [this.options.targetPath]: value ? value.trim() : null,
    }, { silent: true }); // Silent the updating to prevent an update loop

    // trigger a customer update
    this.triggerMethod('setting:updated', this.options.targetPath);
  },

  onShow() {
    this.model.on(`change:${this.options.targetPath}`, this.updateSelect, this);

    this.updateSelect();
  },

  onDestroy() {
    this.model.off(`change:${this.options.targetPath}`, this.updateSelect, this);
  },

  updateSelect() {
    const value = this.model.get(this.options.targetPath);
    if (value) {
      this.ui.select.val(value);
    } else if (this.options.autoSelect && this.options.items.length === 1) {
      const item = this.options.items[0];
      this.model.set(this.options.targetPath, item.id);
      this.triggerMethod('setting:updated', this.options.targetPath);
    } else {
      this.ui.select.val('');
    }
  },

}));
