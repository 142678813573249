define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/behaviors/cellWithKeyboard',
  'modules/shop.cash-register-retail/models/keyboard',
], (
  $, _, Backbone, Locale,
  WithKeyboardBehaviour, KeyboardModel,
) => Backbone.Marionette.LayoutView.extend({

  template() {}, // No template needed.

  tagName: 'input',

  className: 'form-control',

  attributes: {
    readonly: 'readonly',
    style: 'background-color: #fff;',
    type: 'text',
    placeholder: Locale.translate('length'),
  },

  behaviors: {
    WithKeyboardBehaviour: {
      behaviorClass: WithKeyboardBehaviour,
    },
  },

  initialize() {
    this.keyboardMode = KeyboardModel.MODE_PRODUCT_DESCRIPTION_LENGTH;
    this.previousLength = this.model.get('length');
  },

  onShow() {
    this.setValue();
    this.model.on('change:length', this.setValue, this);
  },

  onDestroy() {
    this.model.off('change:length', this.setValue, this);
  },

  setValue() {
    const data = this.model.toJSON();
    this.$el.val(this.model.formatPrice(data.length || '0.00'));
  },

  onModeSelected() {
    this.previousLength = this.model.get('length');
  },

  onKeyPress(key) {
    if (this.keyboardModel.isNumberComaKey(key)) {
      let currentLength = this.model.get('length');
      if (this.firstInput) {
        currentLength = '0.00';
        this.firstInput = false;
      }
      let newLength = this.handleInputOnPrice(currentLength, key);
      if (parseFloat(newLength) > 100000.00) {
        newLength = '100000.00';
      }

      this.model.set({
        length: newLength,
      });
    }
  },

  onBackspacePress() {
    const newLength = this.removeDigitFromPrice(this.paymentModel.get('length'));
    this.paymentModel.set('length', newLength);
  },

  onModeDeselected() {
    if (this.lengthChanged()) {
      this.confirmDescription();
    } else {
      this.resetDescription();
    }
  },

  onConfirmation() {
    this.model.save();
    this.model.trigger('change');
    this.confirmDescription();
    this.model.setLength(
      this.model.get('length'),
    );
  },

  lengthChanged() {
    const currentLength = this.model.get('length');
    const { previousLength } = this;
    if (currentLength !== '0.00') {
      return false;
    }
    return currentLength !== previousLength;
  },

  confirmDescription() {
    this.previousLength = this.model.get('length');
  },

  resetDescription() {
    this.model.set({
      length: this.previousLength,
    });
  },

  onCancel() {
    this.resetDescription();
  },

}));
