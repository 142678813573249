define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/views/products/list/cellWithKeyboard',
  'modules/shop.cash-register-retail/templates/products/list/cellAddonTotal.hbs',
], (
  $, _, Backbone, CellView, Template,
) => CellView.extend({

  template: Template,

  initialize(options) {
    this.productModel = options.productModel;
  },

  onShow() {
    this.productModel.on('change:sub_items', this.render, this);
    this.productModel.on('change:price_wt', this.render, this);
  },

  onDestroy() {
    this.productModel.off('change:sub_items', this.render, this);
    this.productModel.off('change:price_wt', this.render, this);
  },

  serializeData() {
    const data = this.productModel.toJSON();
    data.addonTotalWt = this.productModel.getPriceWtWithSubItems();
    return data;
  },

}));
