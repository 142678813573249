define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/behaviors/cellWithKeyboard',
], (
  $, _, Backbone, WithKeyboardBehaviour,
) => Backbone.Marionette.LayoutView.extend({

  tagName: 'td',

  behaviors: {
    WithKeyboardBehaviour: {
      behaviorClass: WithKeyboardBehaviour,
    },
  },

  className() {
    return `${this.model.get('id')} text-right`;
  },

  initialize(options) {
    options = options || {};
    if (!this.productModel) {
      throw new Error('Need productModel');
    }
    this.onKeyPadOpen = options.onKeyPadOpen;
  },

  onModeSelected() {
    if (this.onKeyPadOpen) {
      this.onKeyPadOpen();
    }
  },

  onConfirmation() {
    this.productModel.save();
    this.productModel.trigger('change');
  },

}));
