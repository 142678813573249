define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/views/products/list/cell',
  'modules/shop.cash-register-retail/templates/products/list/cellDeliverQuantity.hbs',
], (
  $, _, Backbone, CellView, Template,
) => CellView.extend({

  template: Template,

  initialize({ productModel }) {
    this.productModel = productModel;
  },

  onShow() {
    this.productModel.on('change:quantity', this.render, this);
    this.productModel.on('change:to_be_shipped_quantity', this.render, this);
  },

  onDestroy() {
    this.productModel.off('change:quantity', this.render, this);
    this.productModel.off('change:to_be_shipped_quantity', this.render, this);
  },

  serializeData() {
    const { unfulfilled_quantity, to_be_shipped_quantity } = this.productModel.toJSON();
    return {
      quantity: unfulfilled_quantity,
      shippedQuantity: to_be_shipped_quantity,
    };
  },

}));
