define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/posSettings/printerSetting.hbs',

  'modules/common/components/locale',

  'modules/shop.cash-register-retail/models/settings/mediaType',
], (
  $, _, Backbone, Template,
  Locale,
  MediaTypeSetting,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  defaults: {
    label: '',
  },

  initialize(options) {
    this.options = _.extend({}, this.defaults, options);

    this.printerModel = options.printerModel;
  },

  serializeData() {
    return {
      printerId: this.printerModel.get('id'),
      label: this.options.label,
      mediaTypes: MediaTypeSetting.getFullCollection().toJSON(),
    };
  },

  ui: {
    mainType: '[data-ui=main-type]',
    stickerSubType: '[data-ui=sticker-sub-type]',
  },

  events: {
    'change @ui.mainType': 'mainTypeChanged',
    'change @ui.stickerSubType': 'stickerSubTypeChanged',
  },

  mainTypeChanged() {
    const printerId = this.printerModel.get('id');
    const mainType = this.ui.mainType.val();

    this.ui.stickerSubType.hide();

    // Update current value
    if (mainType === 'shelve') {
      this.model.set({
        shelveCardPrinterId: printerId,
      });

      // trigger update
      this.triggerSettingUpdate('shelveCardPrinterId');

      // unset previous value
      if (this.model.get('stickerPrinterId') === printerId) {
        // Unset stickerPrinterId
        this.model.set('stickerPrinterId', null);
        this.triggerSettingUpdate('stickerPrinterId');

        // unset stickerPrinterMediaType
        this.model.set('stickerPrinterMediaType', null);
        this.triggerSettingUpdate('stickerPrinterMediaType');
      }
    } else if (mainType === 'sticker') {
      this.model.set({
        stickerPrinterId: printerId,
        stickerPrinterMediaType: MediaTypeSetting.TYPE_PRICE_STICKER,
      });

      // trigger update
      this.triggerSettingUpdate('stickerPrinterId');
      this.triggerSettingUpdate('stickerPrinterMediaType');

      // Show and trigger sticker type
      this.ui.stickerSubType.show();

      // unset previous value
      if (this.model.get('shelveCardPrinterId') === printerId) {
        // unset shelveCardPrinterId
        this.model.set('shelveCardPrinterId', null);
        this.triggerSettingUpdate('shelveCardPrinterId');
      }
    }
  },

  triggerSettingUpdate(path) {
    // trigger a customer update
    this.triggerMethod('setting:updated', path);
  },

  stickerSubTypeChanged() {
    if (this.model.get('stickerPrinterId') === this.printerModel.get('id')) {
      const stickerType = this.ui.stickerSubType.val();
      this.model.set({
        stickerPrinterMediaType: stickerType,
      });

      // trigger update
      this.triggerSettingUpdate('stickerPrinterMediaType');
    }
  },

  onShow() {
    this.model.on('change:shelveCardPrinterId', this.updateSettings, this);

    this.model.on('change:stickerPrinterId', this.updateSettings, this);
    this.model.on('change:stickerPrinterMediaType', this.updateSettings, this);

    this.setSettings();
  },

  onDestroy() {
    this.model.off('change:shelveCardPrinterId', this.updateSettings, this);

    this.model.off('change:stickerPrinterId', this.updateSettings, this);
    this.model.off('change:stickerPrinterMediaType', this.updateSettings, this);
  },

  setSettings() {
    const printerId = this.printerModel.get('id');
    if (printerId === this.model.get('shelveCardPrinterId')) {
      this.ui.mainType.val('shelve');
    }

    if (printerId === this.model.get('stickerPrinterId')) {
      this.ui.mainType.val('sticker');
      this.ui.stickerSubType.val(this.model.get('stickerPrinterMediaType') || MediaTypeSetting.TYPE_PRICE_STICKER);
      this.ui.stickerSubType.show();
    }
  },

  updateSettings() {
    const printerId = this.printerModel.get('id');

    if (this.model.has('shelveCardPrinterId')) {
      if (this.ui.mainType.val() === 'shelve' && printerId !== this.model.get('shelveCardPrinterId')) {
        this.ui.mainType.val(''); // reset
      }
    }

    if (this.model.has('stickerPrinterId')) {
      if (this.ui.mainType.val() === 'sticker' && printerId !== this.model.get('stickerPrinterId')) {
        this.ui.mainType.val('');
        this.ui.stickerSubType.val(MediaTypeSetting.TYPE_PRICE_STICKER);
        this.ui.stickerSubType.hide();
      }
    }
  },

}));
