define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/posSettings/paymentMethod',

  'modules/common/components/locale',

  'modules/shop.cash-register-retail/models/settings/paymentMethods',
], (
  $, _, Backbone, Template,
  Locale,
  PaymentMethodsSetting,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'row',

  initialize(options) {
    this.loyaltyProgramModel = options.loyaltyProgramModel || null;
  },

  serializeData() {
    const allowedPaymentMethods = PaymentMethodsSetting
      .getAllowedPaymentMethodCollection().toJSON();
    const paymentMethods = [];

    allowedPaymentMethods.forEach((allowedPaymentMethod) => {
      if (
        allowedPaymentMethod.id === PaymentMethodsSetting.LOYALTY_POINTS_METHOD
        && allowedPaymentMethod.enabled
      ) {
        this.showLoyaltyWarning(allowedPaymentMethod);
      }

      paymentMethods.push(allowedPaymentMethod);
    });

    return {
      paymentMethods,
    };
  },

  showLoyaltyWarning(allowedPaymentMethod) {
    if (
      this.loyaltyProgramModel
      && this.loyaltyProgramModel.loaded
      && !this.loyaltyProgramModel.get('allow_points_as_payment')
    ) {
      allowedPaymentMethod.warning = Locale.translate('loyalty_points_can_t_be_used_as_payment_you_can_set_it_in_your_loyalty_program_settings_if_intended');
    }
  },

}));
