define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/productListWithSearch/list/layout',

  'modules/shop.cash-register-retail/views/products/list/layout',
  'modules/shop.cash-register-retail/views/products/description/layout',
  './resultCollection',

  'modules/shop.cash-register-retail/models/keyboard',
  'modules/shop.cash-register-retail/components/productStock',

  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/productCache',

  'modules/shop.cash-register-retail/collections/NaturalSearchShopFlatProduct',
  'modules/shop.cash-register-retail/collections/currentOrderItem',
], (
  $, _, Backbone, Template,
  ProductListView, ProductDescriptionView, ResultCollectionView,
  Keyboard, ProductStock,
  Loader, Toaster, Locale, ProductCache,
  NaturalSearchShopFlatProductCollection, CurrentOrderItemCollection,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'product-list-container',

  behaviors: {
    Loader: {
      behaviorClass: Loader,
      name: 'product-list',
    },
  },

  regions: {
    table: '[data-region=table]',
  },

  modelEvents: {
    'start:search': 'startSearch',
    'change:mode': 'renderProductList resetSearchQuery',
  },

  initialize(options) {
    this.orderItemCollection = options.orderItemCollection || CurrentOrderItemCollection;
  },

  resetSearchQuery() {
    this.searchQuery = '';
  },

  startSearch(searchQuery) {
    this.searchQuery = searchQuery || '';
    this.renderLiveSearch();
  },

  renderLiveSearch() {
    // Resetting the region to ensure the loader is shown
    // And if you are scrolled to the top again.
    this.getRegion('table').reset();
    this.model.set('productCount', null);

    const def = this.loader.startLoader();
    const naturalSearchShopFlatProductCollection = new NaturalSearchShopFlatProductCollection();
    const naturalSearchShopFlatProductParameters = {
      query: this.searchQuery,
      lang: Locale.getLocale(),
      start: 0,
      limit: 50,
      filters: [{
        name: 'flat_product/product/attribute_set_id__notnull',
        val: true,
      }, {
        name: 'product_price/ppu_wt__notnull',
        val: true,
      }],
    };

    if (this.useStockValues()) {
      // it customer cares about the stock values
      // we only search for the products which are orderable
      naturalSearchShopFlatProductParameters.filters.push(
        {
          name: 'is_orderable',
          val: true,
        },
      );
    }

    // Handle loader deferred.
    def.then((resp) => {
      const { total } = resp;
      this.renderProductResults(naturalSearchShopFlatProductCollection);
      this.model.set('productCount', total);
    }, (resp) => {
      Toaster.error(resp.error);
      this.renderProductList();
    });

    naturalSearchShopFlatProductCollection.fetch({ params: naturalSearchShopFlatProductParameters })
      .then(def.resolve, def.reject);
  },

  onRender() {
    this.resetSearchQuery();
    this.renderProductList();
  },

  useStockValues() {
    return ProductStock.useStockValues();
  },
  getColumns() {
    // Initial columns
    const columns = [
      'quantity',
      'description',
    ];

    // Add the stock columns if display_stock_values is enabled
    if (this.useStockValues()) {
      columns.push('stock');
    }

    // Push the other columns
    columns.push(
      'ppu',
      'discount',
      'price',
      'delete',
    );

    return columns;
  },

  getProductListView() {
    return new ProductListView({
      columns: this.getColumns(),
      collection: this.orderItemCollection,
    });
  },

  renderProductList(updatedProductModel) {
    const region = this.getRegion('table');
    const view = this.getProductListView();
    region.show(view);
    view.scrollToUpdatedProduct(updatedProductModel);
    view.on('product:clicked', (product) => this.renderProductDescription(product));
    view.on('product:needs-weight', (product) => this.renderProductDescription(product));
    view.on('product:needs-description', (product) => this.renderProductDescription(product));
  },

  renderProductDescription(productModel) {
    const region = this.getRegion('table');
    const view = new ProductDescriptionView({
      model: productModel,
    });
    region.show(view);

    Keyboard.setViewWithMode(view.cid, Keyboard.MODE_PRODUCT_ORDER_DESCRIPTION);
    view.on('view:closed', () => {
      Keyboard.resetMode();
      this.renderProductList(productModel);
    });
  },

  renderProductResults(naturalSearchShopFlatProductCollection) {
    const region = this.getRegion('table');
    const view = new ResultCollectionView({
      collection: naturalSearchShopFlatProductCollection,
      onlyOrderable: this.useStockValues(),
    });
    region.show(view);

    view.on('product:selected', (product) => {
      ProductCache.storeShopProduct(product);

      this.orderItemCollection.addProductByShopFlatProductModel(
        product,
      ).then(
        (orderItem) => {
          this.model.set('mode', 'barcode');
          if (
            orderItem.get('needs_weight')
              || orderItem.get('needs_description')
          ) {
            this.renderProductDescription(orderItem);
          } else {
            this.renderProductList();
          }
        },
      );
    });
  },

}));
