define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/posSettings/productCacheSetting.hbs',

  'modules/admin/behaviors/loader',
  'modules/common/components/locale',
  'modules/common/components/moment',

  'modules/shop.cash-register-retail/views/popups/productCacheSettingSyncPopup',

  'modules/shop.cash-register-retail/components/productCache',
  'modules/shop.cash-register-retail/models/productCacheConfig',
], (
  $, _, Backbone, Template,
  Loader, Locale, Moment,
  ProductCacheSettingSyncPopupView,
  ProductCache, ProductCacheConfigModel,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'row',

  events: {
    'click [data-action="synchronize"]': 'synchronizeClicked',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
      name: 'product-cache',
    },
  },

  initialize() {
    this.needsSynchronization = false;
  },

  onShow() {
    this.checkSynchronizationStatus();
  },

  checkSynchronizationStatus() {
    const def = this.loader.startLoader();

    Promise.all([
      this.checkNewProducts(),
      this.checkUpdatedProducts(),
    ]).then((results) => {
      const newProducts = results[0];
      const updatedProducts = results[1];

      if (newProducts.total > 0 || updatedProducts.total > 0) {
        this.needsSynchronization = true;
      } else {
        this.needsSynchronization = false;
      }

      this.render();
      def.resolve();
    }, def.reject);
  },

  checkNewProducts() {
    const lastId = ProductCacheConfigModel.getLastId();

    const params = {
      query: 0,
      lang: 0,
      start: 0,
      limit: 1,
      sort: [{
        name: 'id',
        dir: 'asc',
      }],
      filters: [{
        name: 'id__>',
        val: lastId,
      }],
    };

    return ProductCache.callProductSearch(params);
  },

  checkUpdatedProducts() {
    const lastTime = ProductCacheConfigModel.getLastUpdateDate();
    const lastIds = ProductCacheConfigModel.getUpdateDateIds();
    const params = {
      query: 0,
      lang: 0,
      start: 0,
      limit: 1,
      sort: [
        {
          name: 'flat_product/product/date_updated',
          dir: 'asc',
        },
        {
          name: 'id',
          dir: 'asc',
        },
      ],
      update_after_incl: lastTime.toISOString(),
      exclude_ids: lastIds,
      filters: [
        {
          name: 'id__<=',
          val: ProductCacheConfigModel.getLastId(),
        },
      ],
    };

    return ProductCache.callProductSearch(params, 'naturalSearchShopFlatProductsForCacheUpdate');
  },

  synchronizeClicked: _.debounce(function () {
    const popupView = new ProductCacheSettingSyncPopupView();
    popupView.on('popup:closed', () => {
      this.checkSynchronizationStatus();
    });
    popupView.open(
      Locale.translate('synchronizing_cache'),
    );
  }, 100),

  serializeData() {
    let lastSyncDate = ProductCacheConfigModel.get('lastUpdateDate');

    if (lastSyncDate) {
      lastSyncDate = Moment(lastSyncDate).format('LLL');
    }

    return {
      needsSynchronization: this.needsSynchronization,
      last_sync_date: lastSyncDate,
    };
  },

}));
