define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/swappableWithLoading',

  'modules/shop.cash-register-retail/views/products/catalog/printMultiplePriceTags/layout',
  'modules/shop.cash-register-retail/views/products/catalog/printMultiplePriceTags/printing',
], (
  $, _, Backbone, SwappableView,
  LayoutView, PrintingView,
) => SwappableView.extend({

  initialize(options) {
    SwappableView.prototype.initialize.call(this, options);

    this.setMainView(LayoutView, options);
    this.setView('printing', PrintingView, options);
  },

}));
