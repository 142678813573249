define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/currency',
], (
  $, _, Backbone, Currency,
) => Backbone.Marionette.LayoutView.extend({

  tagName: 'td',

  extraClassName: '',

  className() {
    return `${this.model.get('id')} ${this.extraClassName}`;
  },

  serializeData() {
    const data = this.options.productModel.toJSON();
    return data;
  },

}));
