define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/views/products/list/cell',
  'modules/shop.cash-register-retail/templates/products/list/cellDescription',
  'modules/common/components/currency',
], (
  $, _, Backbone, CellView, Template, Currency,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'td',

  events: {
    click: 'handleCellClicked',
  },

  handleCellClicked() {
    if (this.options.isEditable) {
      this.triggerMethod('product:clicked', this.options.productModel);
    }
  },

  initialize(options) {
    options = options || {};
    this.productModel = options.productModel;
    this.showUnitPriceBelowDescription = options.showUnitPriceBelowDescription;
    this.itemPriceWithName = options.itemPriceWithName;
  },

  onShow() {
    this.productModel.on('change:name', this.render, this);
    this.productModel.on('change:sub_items', this.render, this);
    this.productModel.on('change:code', this.render, this);
    this.productModel.on('change:description', this.render, this);
    if (this.showUnitPriceBelowDescription) {
      this.productModel.on('change:ppu_wt', this.render, this);
      this.productModel.on('change:discount_percentage', this.render, this);
    }
  },

  onDestroy() {
    this.productModel.off('change:name', this.render, this);
    this.productModel.off('change:sub_items', this.render, this);
    this.productModel.off('change:code', this.render, this);
    this.productModel.off('change:description', this.render, this);
    if (this.showUnitPriceBelowDescription) {
      this.productModel.off('change:ppu_wt', this.render, this);
      this.productModel.off('change:discount_percentage', this.render, this);
    }
  },

  serializeData() {
    const data = this.productModel.toJSON();
    data.needsWeight = this.productModel.get('featured_attributes.needs_weight_on_kassa.value') === '1';
    const subItems = data.sub_items = this.productModel.getCalculatedSubItems();

    const length_ppu_wt = Currency.currencyToFloat(data.length_ppu_wt);
    data.hasLengthPPuWt = length_ppu_wt > 0;
    data.showUnitPriceBelowDescription = this.showUnitPriceBelowDescription;
    data.itemPriceWithName = !!this.itemPriceWithName && subItems.length > 0;

    return data;
  },

}));
