define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/products/blocks/layout',

  'modules/shop.cash-register-retail/components/restaurantCache',
  'modules/common/components/locale',

  './categories/collection',
  './products/collection',
  'modules/shop.cash-register-retail/views/inputs/productSearchInput',
  'modules/shop.cash-register-retail/views/inputs/stringInput',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.cash-register-retail/models/settings/shopPos.js',

  'modules/admin/behaviors/loader',
], (
  $, _, Backbone, Template,
  RestaurantCache, Locale,
  CategoryCollectionView, ProductCollectionView, ProductSearchInputView, StringInputView,
  CurrentOrderItem, ShopPos,
  Loader,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'product-blocks',

  behaviors: {
    Loader: {
		        behaviorClass: Loader,
		    },
  },

  regions: {
    categories: '[data-region=categories]',
    products: '[data-region=products]',
    search: '[data-region=search]',
  },

  initialize() {
    this.categoryId = false;
    this.searchModel = new Backbone.Model({
      value: '',
    });
  },

  onRender() {
    this.renderCategories();
    this.renderSearch();
    this.setSearchMode();
    this.renderProducts();
  },

  setSearchMode() {
    if (RestaurantCache.getSearchMode() === RestaurantCache.SEARCH_MODE_PRODUCT) {
      this.ui.barcode.removeClass('selected');
      this.ui.product.addClass('selected');
    } else {
      this.ui.barcode.addClass('selected'); // fallback
      this.ui.product.removeClass('selected');
    }
  },

  renderCategories() {
    const categoryCollection = RestaurantCache.getCategoryCollection();
    categoryCollection.add({
      title: Locale.translate('all'),
      id: false,
    });

    const mainModel = categoryCollection.get(ShopPos.get('retail_main_category_id'));
    if (mainModel) {
      categoryCollection.remove(mainModel);
      categoryCollection.unshift(mainModel);
    }
    const region = this.getRegion('categories');
    const view = new CategoryCollectionView({
      collection: categoryCollection,
    });
    region.show(view);

    categoryCollection.on('all', _.debounce(() => {
      this.searchModel.set({
        value: '',
      }, { silent: true });

      const model = categoryCollection.findWhere({ selected: true });
      this.categoryId = false;
      if (model) {
        this.categoryId = model.get('id');
      }
      this.renderProducts();
    }, 10));

    categoryCollection.first().set('selected', true);
  },

  /**
		 * Rendering this view causes the UI to freezes for a lil bit.
		 */
  renderProducts() {
    const def = this.loader.startLoader();
    // This setTimeout makes sure we give the rest of the UI time to render first.
    setTimeout(() => {
      const region = this.getRegion('products');
      const view = new ProductCollectionView({
        collection: RestaurantCache.getProductCollection(),
        categoryId: this.categoryId,
        searchModel: this.searchModel,
      });
      view.on('child:clicked', (model) => {
        CurrentOrderItem.addProductByShopFlatProductModel(model);
      });
      region.show(view);

      // This setTimeout ensures you dont click on any of the products by accident.
      setTimeout(() => def.resolve(), 0);
    }, 0);
  },

  renderSearch(autoFocus) {
    const region = this.getRegion('search');
    region.empty();
    let view;
    if (RestaurantCache.getSearchMode() === RestaurantCache.SEARCH_MODE_PRODUCT) {
      this.ui.clearInput.show();
      view = new StringInputView({
        autoFocus,
        placeholder: Locale.translate('search_for_product_in_current_categorie'),
      });
      view.on('value:search', (value) => {
        this.searchModel.set('value', value);
      });
    } else {
      this.ui.clearInput.hide();
      view = new ProductSearchInputView({
        checkoutBtn: this.options.checkoutBtn,
        placeholder: Locale.translate('scan_barcode_to_add_product'),
      });
      view.on('search:clicked', () => {
        setTimeout(() => this.selectMode(RestaurantCache.SEARCH_MODE_PRODUCT, true), 0);
      });
    }

    region.show(view);
  },

  ui: {
    barcode: '[data-ui=barcode]',
    product: '[data-ui=product]',
    clearInput: '[data-ui=clear-input]',
  },

  events: {
    'click @ui.barcode': 'barcodeClicked',
    'click @ui.product': 'productClicked',
    'click @ui.clearInput': 'clearInputClicked',
  },

  clearInputClicked(ev) {
    ev.currentTarget.blur();

    this.selectMode(RestaurantCache.SEARCH_MODE_PRODUCT, true);
  },

  barcodeClicked(ev) {
    ev.currentTarget.blur();

    this.selectMode(RestaurantCache.SEARCH_MODE_BARCODE, true);
  },

  productClicked(ev) {
    ev.currentTarget.blur();

    this.selectMode(RestaurantCache.SEARCH_MODE_PRODUCT, true);
  },

  selectMode(wantedMode, autoFocus) {
    this.searchModel.set('value', '');
    if (RestaurantCache.getSearchMode() !== wantedMode) {
      RestaurantCache.setSearchMode(wantedMode);
      this.setSearchMode();
    }
    this.renderSearch(autoFocus);
  },

}));
