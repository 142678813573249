define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/locale',
  'modules/common/components/currency',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',

  'modules/shop.cash-register-retail/behaviors/cellWithKeyboard',
  'modules/shop.cash-register-retail/models/keyboard',
], (
  $, _, Backbone, Locale, Currency, DefaultShopConfigurationModel,
  WithKeyboardBehaviour, KeyboardModel,
) => Backbone.Marionette.LayoutView.extend({

  template() {}, // No template needed.

  tagName: 'input',

  className: 'form-control',

  attributes: {
    readonly: 'readonly',
    style: 'background-color: #fff;',
    type: 'text',
    placeholder: Locale.translate('price'),
  },

  behaviors: {
    WithKeyboardBehaviour: {
      behaviorClass: WithKeyboardBehaviour,
    },
  },

  initialize() {
    this.keyboardMode = KeyboardModel.MODE_PRODUCT_DESCRIPTION_PRICE;
    this.previousPpu = this.model.get('length_ppu_wt');
  },

  onShow() {
    this.setValue();
    this.model.on('change:length_ppu_wt', this.setValue, this);
  },

  onDestroy() {
    this.model.off('change:length_ppu_wt', this.setValue, this);
  },

  setValue() {
    const data = this.model.toJSON();
    this.$el.val(Currency.format(DefaultShopConfigurationModel.getCurrencyIso3(), data.length_ppu_wt || '0.00'));
  },

  onModeSelected() {
    this.previousPpu = this.model.get('length_ppu_wt');
  },

  onKeyPress(key) {
    if (this.keyboardModel.isNumberComaKey(key)) {
      let currentPrice = this.model.get('length_ppu_wt');
      if (this.firstInput) {
        currentPrice = '0.00';
        this.firstInput = false;
      }
      let newPrice = this.handleInputOnPrice(currentPrice, key);
      if (parseFloat(newPrice) > 100000.00) {
        newPrice = '100000.00';
      }

      this.model.set({
        length_ppu_wt: newPrice,
      });
    }
  },

  onBackspacePress() {
    const newPrice = this.removeDigitFromPrice(this.paymentModel.get('length_ppu_wt'));
    this.paymentModel.set('length_ppu_wt', newPrice);
  },

  onModeDeselected() {
    if (this.lengthPpuChanged()) {
      this.confirmPrice();
    } else {
      this.resetPrice();
    }
  },

  onConfirmation() {
    this.model.save();
    this.model.trigger('change');
    this.confirmPrice();
    this.model.setLengthPpuWt(
      this.model.get('length_ppu_wt'),
    );
  },

  lengthPpuChanged() {
    const currentPrice = this.model.get('length_ppu_wt');
    const previousPrice = this.previousPpu;
    if (currentPrice !== '0.00') {
      return false;
    }
    return currentPrice !== previousPrice;
  },

  confirmPrice() {
    this.previousPpu = this.model.get('length_ppu_wt');
  },

  resetPrice() {
    this.model.set({
      length_ppu_wt: this.previousPpu,
    });
  },

  onCancel() {
    this.resetPrice();
  },

}));
