define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/views/products/list/cellWithKeyboard',
  'modules/shop.cash-register-retail/templates/products/list/cellQuantity',
  'modules/shop.cash-register-retail/collections/currentOrderItem',
], (
  $, _, Backbone, CellView, Template, OrderItemCollection,
) => CellView.extend({

  template: Template,

  className() {
    return `${this.model.get('id')} text-center`;
  },

  initialize(options) {
    options = options || {};

    this.productModel = options.productModel;
    this.keyboardMode = this.keyboardModel.MODE_PRODUCT_QUANTITY;

    CellView.prototype.initialize.call(this, options);
  },

  onShow() {
    this.productModel.on('change:quantity', this.render, this);
  },

  onDestroy() {
    this.productModel.off('change:quantity', this.render, this);
  },

  onModeSelected() {
    this.previousQuantity = this.productModel.get('quantity');
    CellView.prototype.onModeSelected.call(this);
  },

  onModeDeselected() {
  },

  onKeyPress(key) {
    if (this.keyboardModel.isNumberKey(key)) {
      if (this.firstInput) {
        this.firstInput = false;
        this.updateQuantity(parseInt(key));
      } else {
        const currentQuantity = this.productModel.get('quantity');
        let newQuantity = parseInt(`${currentQuantity}${key}`);

        if (newQuantity > 10000) {
          newQuantity = 10000;
        }
        this.updateQuantity(newQuantity);
      }
    }
  },

  updateQuantity(quantity) {
    const type = this.productModel.get('type');
    if (
      type === OrderItemCollection.TYPE_NEGATIVE_PRODUCT
        || type === OrderItemCollection.TYPE_USED_GOODS
    ) {
      if (quantity > 0) {
        quantity = -quantity;
      }
    }
    this.productModel.updateQuantity(quantity);
  },

  onBackspacePress() {
    // Mark as changed
    this.firstInput = false;
    // Update the quantity
    let newQuantity = this.removeLastCharacterFromString(this.productModel.get('quantity') || '');
    if (newQuantity === '' || newQuantity === '-') newQuantity = 0;

    this.updateQuantity(newQuantity);
  },

  onConfirmation() {
    const type = this.productModel.get('type');
    const newQuantity = parseInt(this.productModel.get('quantity'));
    if (newQuantity === 0) {
      // having 0 quantity it not valid so setting to 1
      this.updateQuantity(1);
    }
    if (this.productModel.hasFilledInSerialNumbers()) {
      const serialCount = (this.productModel.get('serial_nos') || []).length;
      if (type === OrderItemCollection.TYPE_USED_GOODS) {
        // used goods have negative quantity
        if (serialCount > -newQuantity) {
          this.updateQuantity(serialCount);
        }
      } else if (serialCount > newQuantity) {
        this.updateQuantity(serialCount);
      }
    }
    CellView.prototype.onConfirmation.call(this);
    this.previousQuantity = this.productModel.get('quantity');
    this.productModel.calculateTotalPrices();
  },

  onCancel() {
    this.productModel.setQuantity(this.previousQuantity);
  },

  afterCellClicked() {
    this.triggerMethod('product:quantity', {
      productModel: this.productModel,
    });
  },

  serializeData() {
    const data = this.productModel.toJSON();
    return data;
  },

}));
